import { useContext, useEffect } from "react";
import MapContext from "../Map/MapContext";
import OLTileLayer from "ol/layer/Tile";
import Crop from "ol-ext/filter/Crop";
import Feature from 'ol/Feature';
import MultiPolygon from 'ol/geom/MultiPolygon';

const TileLayer = ({ name, baseLayer, visible = true, extent, source, zIndex = 0, opacity = 1, className = null, cropCoordinates = null, logo = null }) => {
	const { map } = useContext(MapContext);

	useEffect(() => {
		if (!map) return;

		let tileLayer = new OLTileLayer({
			className,
			name,
			baseLayer,
			visible,
			extent,
			source,
			zIndex,
			opacity,
			logo
		});

		if (cropCoordinates) {
			const f = new Feature(new MultiPolygon(cropCoordinates));
			const crop = new Crop({
				feature: f,
				wrapX: true,
				inner: false
			})
			tileLayer.addFilter(crop);
		}

		map.addLayer(tileLayer);
		tileLayer.setZIndex(zIndex);

		return () => {
			if (map) {
				map.removeLayer(tileLayer);
			}
		};
	}, [map]);

	return null;
};

export default TileLayer;
