import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, CardTitle, FormGroup, Label, Input } from "reactstrap";
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import MapContext from "../Map/MapContext";
import IntlText from '../IntlText';

const LeftSideBarControl = ({ uuid, layer, toggleLayerVisibility }) => {
  const [isLayerVisible, setLayerVisible] = useState(layer.visible);
  const { map } = useContext(MapContext);
  const [popoverOpen, setPopoverOpen] = useState(false);

  useEffect(() => {
    if (!map) return;

  }, [map]);

  const toggleSidebar = () => {
    setLayerVisible(!isLayerVisible);
    map.getLayers().forEach((ly) => {
      if (ly.get('name') === layer.title) {
        ly.setVisible(!isLayerVisible);
        toggleLayerVisibility(ly);
      }
    });
  };

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  const handleMouseEnter = () => {
    setPopoverOpen(true);
  };

  const handleMouseLeave = () => {
    setPopoverOpen(false);
  };

  return (
    <Card style={{ margin: 5 }}>
      <CardBody style={{paddingBottom: 0}}>
        <FormGroup switch>
          <Input
            type="switch"
            checked={isLayerVisible}
            onClick={toggleSidebar}
            role="switch"
          />
          <Label check>
            <CardTitle tag="h6">
              <div>
                <p
                  id={'layer_' + uuid.replace(/-/g, '')}
                  style={{
                    width: '160px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <IntlText id={layer.title} />
                </p>
                <Popover
                  trigger="hover"
                  placement="right"
                  isOpen={popoverOpen}
                  target={'layer_' + uuid.replace(/-/g, '')}
                  toggle={togglePopover}
                >
                  <PopoverHeader>
                    <IntlText id={layer.title} />
                  </PopoverHeader>
                  <PopoverBody>
                    <img src={`${layer.source.url}?SERVICE=WMS&VERSION=1.1.0&REQUEST=GetLegendGraphic&FORMAT=image/png&LAYER=${layer.source.params.LAYERS}`} alt="Legend" style={{ maxWidth: '100%' }} />
                  </PopoverBody>
                </Popover>
              </div>
            </CardTitle>
          </Label>
        </FormGroup>
      </CardBody>
    </Card>
  );
};

export default LeftSideBarControl;
