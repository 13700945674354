// Header.js
import React from 'react';
import { Navbar, NavbarBrand, Nav, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import IntlText from './IntlText';

import LanguageSwitcher from './LanguageSwitcher';

const Header = ({ locales, handleChangeLocale }) => {
  return (
    <Navbar color="light" light expand="md">
      <NavbarBrand tag={Link} to="/">
        {
         // <img src='/img/logo.png' alt="Logo" style={{ marginRight: '10px' }} />
        }
        <IntlText id="appName" />
      </NavbarBrand>
      <Nav className="ml-auto" navbar>
        <NavItem>
          <Link className="nav-link" to="/"><IntlText id="home" /></Link>
        </NavItem>
        <NavItem>
          <Link className="nav-link" to="/about"><IntlText id="about" /></Link>
        </NavItem>
        <LanguageSwitcher locales={locales} handleChangeLocale={handleChangeLocale}/>
      </Nav>
    </Navbar>
  );
};

export default Header;
