import React, { useContext, useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, Container, TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Table, Card, CardBody } from 'reactstrap';
import ImageWMS from 'ol/source/ImageWMS';
import TileWMS from 'ol/source/TileWMS';
import { useIntl } from 'react-intl';
import MapContext from "../Map/MapContext";
import IntlText from '../IntlText';

const FeatureInfoControl = ({ layers }) => {
	const intl = useIntl();
	const { map } = useContext(MapContext);
	const [modal, setModal] = useState(false);
	const [activeTab, setActiveTab] = useState('0');
	const [infoResults, setInfoResults] = useState([]);

	useEffect(() => {
		if (!map) return;

		map.on('singleclick', showInfo);

		return () => map.un('singleclick', showInfo);

	}, [map]);

	const toggle = () => {
		setModal(!modal);
		if (!modal === false) {

		}
	};

	const toggleTab = tab => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	const isImageUrl = (url) => {
		var imageExtensions = /\.(jpg|jpeg|png|gif|bmp)$/i;
		return imageExtensions.test(url);
	};

	const showInfo = (evt) => {
		const viewResolution = map.getView().getResolution();
		const coordinate = evt.coordinate;

		const fetchPromises = [];

		map.getLayers().forEach(function (layer) {
			if ((layer.getSource() instanceof ImageWMS || layer.getSource() instanceof TileWMS) && layer.getSource() && layer.get('visible') === true) {
				var source = layer.getSource();
				var url = source.getFeatureInfoUrl(coordinate, viewResolution, 'EPSG:3857', {
					'INFO_FORMAT': 'application/json'
				});

				if (url) {
					fetchPromises.push(
						fetch(url)
							.then(function (response) {
								return response.json();
							})
							.then(function (json) {
								return json.features;
							})
					);
				}
			}
		});

		Promise.all(fetchPromises)
			.then(function (results) {
				let combinedResults = results.flat();
				setInfoResults(combinedResults);
				combinedResults.length > 0 && toggle();
			})
			.catch(function (error) {
				console.error('Error fetching data:', error);
			});

	};

	const fieldRenderer = (data) => {
		try {
			let tmpData = data;

			if (data.includes('|')) {
				tmpData = data.split('|');
			} else if (data.includes(';')) {
				tmpData = data.split(';');
			}

			if (Array.isArray(tmpData)) {
				return (
					<React.Fragment>
						{tmpData.map((d, i) => (
							<div key={i}>{i + 1}) {d.includes('http') ? <a href={d} target="new">{isImageUrl(d) ? <img src={d} alt="d" width={'95%'} style={{ margin: '5px' }} /> : d}</a> : d}</div>
						))}
					</React.Fragment>
				);
			} else {
				return (
					<div>{tmpData.includes('http') ? <a href={tmpData} target="new">{isImageUrl(tmpData) ? <img src={tmpData} alt="d" width={'95%'} /> : tmpData}</a> : tmpData}</div>
				);
			}
		} catch (err) {
			console.error("Error in fieldRenderer:", err);
			return null;
		}
	};


	return <div>
		<Modal isOpen={modal} toggle={toggle} size={'lg'}>
			<ModalHeader toggle={toggle}><IntlText id="infoResutls" /></ModalHeader>
			<ModalBody>
				<Container>
					<div>
						<Nav tabs>
							{infoResults.map((result, index) => (
								<NavItem key={index}>
									<NavLink
										className={activeTab === index.toString() ? 'active' : ''}
										onClick={() => { toggleTab(index.toString()); }}
									>
										<IntlText id={result.id.split('.')[0]} />
									</NavLink>
								</NavItem>
							))}
						</Nav>
						<TabContent activeTab={activeTab}>
							{infoResults.map((result, index) => (
								<TabPane key={index} tabId={index.toString()}>
									<Row>
										<Col sm="12">
											<Card><CardBody>
												<Table striped style={{ fontSize: 'small' }}>
													<tbody>
														{
															layers.filter(ly => ly.source.params.LAYERS === result.id.split('.')[0])[0].fields.map(f =>
																<tr>
																	<td width={'25%'}>{f[intl.locale === 'gr' ? 'el' : intl.locale === 'us' ? 'en' : intl.locale]}</td>
																	<td>{fieldRenderer(result.properties[f.property])}</td>
																</tr>
															)
														}
													</tbody>
												</Table>
											</CardBody></Card>
										</Col>
									</Row>
								</TabPane>
							))}
						</TabContent>
					</div>
				</Container>
			</ModalBody>
		</Modal>
	</div>;
};

export default FeatureInfoControl;