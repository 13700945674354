import React, { useState, useEffect } from "react";
import { v5 as uuidv5 } from 'uuid';
import Map from "../../components/Map";
import { Layers, TileLayer, ImageLayer } from "../../components/Layers";
import { bing, imageWMS, tileWMS } from "../../components/Source";
import { fromLonLat, transformExtent } from "ol/proj";
import {
  Controls,
  InitialZoomControl,
  ZoomControl,
  ScaleLineControl,
  BasemapControl,
  LeftSideBarControl,
  FeatureInfoControl,
} from "../../components/Controls";

import { TfiMenu } from "react-icons/tfi";


const MapViewer = (props) => {
  const [jsonData, setJsonData] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [center, setCenter] = useState(null);
  const [zoom, setZoom] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('/config.json');
      const data = await response.json();
      setJsonData(data);
      setCenter(fromLonLat(data.view.center));
      setZoom(data.view.zoom);
    };

    fetchData();
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleLayerVisibility = (layer) => {
    const newJson = jsonData;
    newJson.layers.forEach( ly => {
      if (layer.get('name') === ly.title) {
        ly.visible = layer.get('visible')
      }
    });
    setJsonData(newJson);
  };

  const createUUIDFromString = (inputString) => {
    return uuidv5(inputString, uuidv5.URL);
  };

  return (
    <div className="app">
      {jsonData ? (
        <div className="content">
          <div className="map-root-div" style={isSidebarOpen ? { width: 'calc(100% - 250px)', float: 'right' } : { width: '100%', float: 'unset' }}>
            <Map center={center} zoom={zoom} loggedin={props.loggedin}>
              <Layers key={`map-key-${Math.random()}`}>
                <TileLayer title={'Road'} name={'Road'} baseLayer={true} visible={false} source={bing('Road', 'en')} zIndex={0} logo={"/img/road.png"} />
                <TileLayer title={'Aerial'} name={'Aerial'} baseLayer={true} visible={false} source={bing('Aerial', 'en')} zIndex={0} logo={"/img/aerial.png"} />
                <TileLayer title={'Hybrid'} name={'Hybrid'} baseLayer={true} visible={true} source={bing('AerialWithLabelsOnDemand', 'en')} zIndex={0} logo={"/img/hybrid.png"} />
                {
                  jsonData.layers.map((e, i) => <TileLayer
                    key={`layer-${i}`}
                    name={e.title}
                    baseLayer={false}
                    visible={e.visible}
                    extent={transformExtent(e.extent, 'EPSG:4326', 'EPSG:3857')}
                    opacity={e.opacity}
                    minZoom={e.minZoom}
                    maxZoom={e.maxZoom}
                    source={tileWMS({
                      url: e.source.url,
                      params: e.source.params
                    })} />)
                }
              </Layers>
              <Controls>
                <InitialZoomControl center={center} zoom={zoom} />
                <ZoomControl />
                <ScaleLineControl />
                <BasemapControl />
                <FeatureInfoControl key={`map-info-key-${Math.random()}`} layers={jsonData.layers}/>
                <React.Fragment>
                  <button className={isSidebarOpen ? "toogle-button open" : "toogle-button"} onClick={toggleSidebar}><TfiMenu /></button>
                  <div className={isSidebarOpen ? "sidebar open" : "sidebar"}>
                    {
                      jsonData.layers.map((e, i) =>
                        <LeftSideBarControl
                          key={`control-layer-${i}`}
                          uuid={createUUIDFromString(e.source.url + e.source.params.LAYERS)}
                          layer={e}
                          toggleLayerVisibility={toggleLayerVisibility}
                        />
                      )
                    }
                    <div className="sidebar-logo"><img src='/img/logo.png' alt="Logo" /></div>
                  </div>
                </React.Fragment>
              </Controls>
            </Map>
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default MapViewer;