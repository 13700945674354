import * as olSource from "ol/source";

function bing(imagerySet, locale, logo) {
	return new olSource.BingMaps({
		key: 'AplIOGS3KYwzsjXkNdTU40UTzIecZlj--vdlCIIKHHYlJCj0NW99t0HIETkwEmrk',
		imagerySet: imagerySet,
		culture: locale === 'el' ? 'EL-el' : 'En-en'
	});
}

export default bing;