import React from 'react';
import { useIntl } from 'react-intl';

const About = () => {
  const intl = useIntl();
  
  return (
    <div className='container'>
      <div dangerouslySetInnerHTML={{ __html: intl.messages['aboutText'] }} />
    </div>
  );
};

export default About;
