import React, { useContext, useEffect, useState } from "react";
import { ScaleLine } from "ol/control";
import MapContext from "../Map/MapContext";

const ScaleLineControl = () => {
	const { map } = useContext(MapContext);

	useEffect(() => {
		if (!map) return;

		let scaleLineControl = new ScaleLine({
			units: 'metric',
			bar: true,
			steps: 4,
			text: false,
			minWidth: 80,
		});

		map.controls.push(scaleLineControl);

		return () => map.controls.remove(scaleLineControl);
	}, [map]);

	return null;
};

export default ScaleLineControl;