import React from 'react';
import ReactCountryFlag from "react-country-flag"

const LanguageSwitcher = ({ locales, handleChangeLocale }) => {
  return (
    <div>
      {locales.map((locale) => (
        <button key={locale} onClick={() => handleChangeLocale(locale)} style={{border: 0, backgroundColor: '#f8f9fa', marginTop: '5px'}}>
          <ReactCountryFlag countryCode={locale.toUpperCase()} svg />
        </button>
      ))}
    </div>
  );
};

export default LanguageSwitcher;
