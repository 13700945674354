// src/components/IntlText.js
import React from 'react';
import { useIntl } from 'react-intl';

const IntlText = ({ id }) => {
  const intl = useIntl();
  return <span>{intl.formatMessage({ id })}</span>;
};

export default IntlText;
