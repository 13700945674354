import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import Header from './components/Header';
import MapViewer from './views/layout/mapViewer';
import About from './views/layout/about';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/style.css";

const App = () => {
    const [locales, setLocales] = useState({});
    const [locale, setLocale] = useState('gr');

    useEffect(() => {
        const fetchLocales = async () => {
            try {
                const [elResponse, enResponse] = await Promise.all([
                    fetch('/el.json'),
                    fetch('/en.json')
                ]);

                const elData = await elResponse.json();
                const enData = await enResponse.json();

                setLocales({
                    gr: elData,
                    us: enData
                });
            } catch (error) {
                console.error('Error fetching locales:', error);
            }
        };

        fetchLocales();
    }, []);

    const handleChangeLocale = (newLocale) => {
        setLocale(newLocale);
    };

    return (
        <BrowserRouter>
            <IntlProvider locale={locale} messages={locales[locale]}>
                <div>
                    <Header locales={Object.keys(locales)} handleChangeLocale={handleChangeLocale} />
                    <Routes>
                        <Route path="/" element={<MapViewer />} />
                        <Route path="/about" element={<About />} />
                    </Routes>
                </div>
            </IntlProvider>
        </BrowserRouter>
    );
};

export default App;
