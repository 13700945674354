import React, { useContext, useEffect, useState } from "react";
import LayerSwitcherImage from "./ol-ext/control/LayerSwitcherImage";
import MapContext from "../Map/MapContext";


const BasemapControl = () => {
	const { map } = useContext(MapContext);

	useEffect(() => {
		if (!map) return;

		const layerSwitcherImageControl = new LayerSwitcherImage({});

		map.controls.push(layerSwitcherImageControl);

		return () => map.controls.remove(layerSwitcherImageControl);
	}, [map]);

	return null;
};

export default BasemapControl;