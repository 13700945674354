import { useContext, useEffect } from "react";
import { Group } from 'ol/layer';
import OLTileLayer from "ol/layer/Tile";
import OLVectorLayer from "ol/layer/Vector";
import MapContext from "../Map/MapContext";

function LayerGroup({ children, name, visible }) {

	const { map } = useContext(MapContext);

	useEffect(() => {
		if (!map) return;

		const layers = children.map((ly) =>
		//  ly.type.name === 'TileLayer' ?
			new OLTileLayer({
				...ly.props
			})
			// :
			// new OLVectorLayer({
			// 	...ly.props
			// })
		)
		let layerGroup = new Group({
			visible,
			name,
			layers
		});

		map.addLayer(layerGroup);

		return () => {
			if (map) {
				map.removeLayer(layerGroup);
			}
		};
	}, [map]);

	return null;
}

export default LayerGroup;
